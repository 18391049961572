import PropTypes from 'prop-types'
import Image from '@components/ui/Image'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import Arrow from '@components/ui/Arrows/Arrows'
import Modal from '@components/ui/Modal'
import Text from '@components/ui/Text'
import cn from '@utils/helpers/cn'
import useWindowSize from '@utils/hooks/useWindowSize'

const ProductSwiper = ({ gallery, name, activeSlider, setActiveSlider }) => {
  const windowSize = useWindowSize()
  const [isModal, setIsModal] = useState(false)
  const carouselRef = useRef(null)
  const modalCarouselRef = useRef(null)
  const { isMobile } = useSelector((state) => state.ui)
  const settings = {
    dots: isMobile,
    infinite: true,
    speed: 0,
    useTransform: false,
    lazyLoad: 'ondemand',
    nextArrow: <Arrow isSlider={true} />,
    prevArrow: <Arrow isSlider={true} left />,
    afterChange: (e) => {
      setActiveSlider(e)
    },
  }

  const changeModalSwiper = (e) => {
    if (e?.key === 'ArrowRight') {
      if (modalCarouselRef?.current?.slickNext) {
        modalCarouselRef.current.slickNext()
      }
    }
    if (e?.key === 'ArrowLeft') {
      if (modalCarouselRef?.current?.slickPrev) {
        modalCarouselRef.current.slickPrev()
      }
    }
  }

  useEffect(() => {
    if (carouselRef.current?.slickGoTo) {
      const timer = setTimeout(() => {
        carouselRef?.current?.slickGoTo(activeSlider)
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [activeSlider])

  useEffect(() => {
    addEventListener('keydown', changeModalSwiper, false)
    if (modalCarouselRef?.current?.slickGoTo) {
      modalCarouselRef.current.slickGoTo(activeSlider)
    }
    return () => {
      removeEventListener('keydown', changeModalSwiper, false)
    }
  }, [isModal])

  return (
    <>
      <div className="relative mx-auto max-h-full w-full max-w-[600px] overflow-hidden pb-0">
        <Slider {...settings} ref={carouselRef}>
          {gallery &&
            gallery.length > 0 &&
            gallery.map((media) => {
              if (media.video) {
                return (
                  <div
                    key={media.video}
                    onClick={() =>
                      windowSize?.width >= 1200 ? setIsModal(true) : null
                    }
                  >
                    <div className="screen-full flex items-center justify-center">
                      <iframe
                        src={media.video}
                        className="z-50 aspect-video w-full"
                      />
                    </div>
                  </div>
                )
              }
              if (media.orbitvu) {
                return (
                  <div
                    key={media.orbitvu}
                    onClick={() =>
                      windowSize?.width >= 1200 ? setIsModal(true) : null
                    }
                  >
                    <div className="screen-full flex items-center justify-center">
                      <iframe
                        src={media.orbitvu}
                        className="z-50 aspect-square w-full"
                      />
                    </div>
                  </div>
                )
              }
              return (
                <div
                  className="object-cover lg:cursor-zoom-in"
                  key={media.image}
                  onClick={() =>
                    windowSize?.width >= 1200 ? setIsModal(true) : null
                  }
                >
                  <Image
                    alt={name}
                    src={media.image}
                    width={600}
                    height={600}
                    className="pointer-events-none max-h-[600px] w-full object-contain print:w-3/5"
                  />
                </div>
              )
            })}
        </Slider>
      </div>
      {isModal && (
        <>
          <div className="fixed left-0 top-0 z-40 h-screen w-screen bg-white/30 shadow backdrop-blur-sm" />
          <Modal
            className="top-[5vh] max-h-[90vh] w-[90vw] max-w-[1500px] translate-y-0"
            closeCallback={() => setIsModal(false)}
          >
            <div className="flex flex-col items-center">
              <Text
                variant="sectionHeading"
                className="mb-[5px] text-[22px] font-bold leading-8 max-lg:text-[30px]"
              >
                {name}
              </Text>
              <div className="max-h-[90vh] w-full max-w-[1500px]">
                <Slider
                  {...{
                    dots: false,
                    infinite: true,
                    fade: true,
                    swipeToSlide: false,
                    afterChange: (e) => {
                      setActiveSlider(e)
                    },
                  }}
                  ref={modalCarouselRef}
                >
                  {gallery &&
                    gallery.length > 0 &&
                    gallery.map((media) => {
                      if (media.video) {
                        return (
                          <div key={media.video}>
                            <div className="screen-full flex items-center justify-center">
                              <iframe
                                src={media.video}
                                className="z-50 aspect-video max-h-[50vh]  w-full"
                              />
                            </div>
                          </div>
                        )
                      }
                      if (media.orbitvu) {
                        return (
                          <div key={media.orbitvu}>
                            <div className="screen-full flex items-center justify-center">
                              <iframe
                                src={media.orbitvu}
                                className="z-50 aspect-video w-full"
                              />
                            </div>
                          </div>
                        )
                      }
                      return (
                        <Image
                          alt={name}
                          src={media.image}
                          quality={75}
                          width={1500}
                          height={1500}
                          className="aspect-square h-[70vh] max-h-[1500px] max-w-[1500px] object-contain"
                          key={media.image}
                        />
                      )
                    })}
                </Slider>
              </div>

              <ul className="z-10 flex max-w-full overflow-x-auto">
                {gallery &&
                  gallery.length > 0 &&
                  gallery.map((media, i) => (
                    <li
                      className={cn('m-1 border border-[#e2e2e2] p-1', {
                        'border-[#D81100]': i === activeSlider,
                      })}
                      key={media.image}
                      onClick={() => {
                        if (modalCarouselRef?.current?.slickGoTo) {
                          modalCarouselRef.current.slickGoTo(i)
                          setActiveSlider(i)
                        }
                      }}
                    >
                      <Image
                        alt={name}
                        src={media.image}
                        width={60}
                        height={60}
                        className="aspect-square max-h-[80px] max-w-[80px] cursor-pointer object-contain"
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </Modal>
        </>
      )}
    </>
  )
}

ProductSwiper.propTypes = {
  gallery: PropTypes.array,
  name: PropTypes.string,
  activeSlider: PropTypes.number,
  setActiveSlider: PropTypes.func,
}

export default ProductSwiper
