import PropTypes from 'prop-types'
import React from 'react'
import cn from '@utils/helpers/cn'
import { useRef } from 'react'
import useOnClickOutside from '@utils/hooks/useClose'

const Modal = ({
  closeCallback,
  children,
  className,
  escape = true,
  ...props
}) => {
  const modalRef = useRef(null)
  useOnClickOutside(modalRef, () => closeModal(), escape)
  const closeModal = () => {
    closeCallback()
  }
  return (
    <div
      ref={modalRef}
      className={cn(
        'container-scroll fixed left-1/2 top-1/2 z-50 m-auto max-h-[90vh] w-[620px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto border-2 border-gray-300 bg-white px-[70px] py-6 text-black shadow-lg max-lg:w-4/5 max-sm:-top-[0] max-sm:left-0 max-sm:m-0 max-sm:h-screen max-sm:w-full max-sm:translate-x-0 max-sm:translate-y-0 max-sm:border-0 max-sm:px-6 max-sm:py-3',
        className,
      )}
      {...props}
    >
      <span
        className="absolute right-12 top-10 z-[11] cursor-pointer text-lg font-bold text-gray-400 max-sm:left-6 max-sm:right-auto max-sm:top-3 max-sm:text-white"
        onClick={closeModal}
      >
        &#10005;
      </span>
      {children}
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  closeCallback: PropTypes.func,
}
export default Modal
