import Price from '@components/ui/Price'
import Text from '@components/ui/Text'
import PropTypes from 'prop-types'
import AddToCartBtn from '@components/ui/AddtoCartBtn'
import RRP from '@components/ui/RRP'
import DeliveryTime from '@components/ui/DeliveryTime'
import useI18n from '@utils/hooks/useI18n'
import { useSelector } from 'react-redux'
import Image from '@components/ui/Image'
import Link from '@components/ui/Link'
import requestItem from '@utils/helpers/requestItem'

const StickyProductCard = ({ product }) => {
  const i18n = useI18n()
  const country = useSelector((state) => state.store.country)
  const selectedCountry = product?.deliverytime?.find(
    (item) => item.country_id === country.code.toUpperCase(),
  )
  const customOptionsData = useSelector(
    (state) => state.cart.customOptions || {},
  )
  return (
    <div className="flex h-[90px] w-full items-center">
      <div className="mr-4">
        <Image
          width={80}
          height={80}
          src={`${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/catalog/product${product?.image}`}
          alt={product?.name}
        />
      </div>
      <div className="flex grow flex-col">
        <Text
          variant="body"
          className="max-w-[650px] truncate text-base font-medium text-[#262e38]"
        >
          {product?.name}
        </Text>
        <Text
          variant="inlineText"
          className="mt-[5px] truncate text-sm text-[#9d9d9d]"
        >
          {i18n.t('SKU') + ' ' + product?.sku}
        </Text>
        <Text
          variant="inlineText"
          className="mt-[5px] truncate text-sm text-[#9d9d9d]"
        >
          {product?.net_dimensions}
        </Text>
      </div>
      <div className="flex flex-col items-start">
        <DeliveryTime
          deliveryTimeMin={selectedCountry?.deliverytime_min}
          deliveryTimeMax={selectedCountry?.deliverytime_max}
          deliveryTimeUnit={selectedCountry?.deliverytime_unit}
        />
        <DeliveryTime
          deliveryTimeUnit={'included'}
          href={'best-price'}
          className="mt-2"
        />
      </div>

      <div className="ml-5 flex flex-col items-baseline justify-end">
        <Price
          price={
            product?.storePrice ? product?.storePrice.price : product?.price
          }
          type="productPageSticky"
        />
        <RRP
          oldPrice={
            product?.storePrice ? product?.storePrice.rrp : product?.rrp
          }
          type="productPageSticky"
          className="mt-3"
        />
      </div>
      <div className="ml-5 w-1/5 min-w-[170px]">
        {(product?.second_choice && Boolean(Number(product.second_choice))) ||
        requestItem(product) ? (
          <Link
            href={requestItem(product) ? requestItem(product) : 'best-price'}
            locale
            className="mr-3 flex w-full items-center justify-center rounded border border-[#298400] bg-gradient-to-b from-[#46C154] to-[#097700] px-1 py-3 text-lg font-semibold text-white outline-none hover:bg-gradient-to-b hover:from-[#33a741] hover:to-[#0C5F05]"
          >
            {i18n.t('Submit request')}
          </Link>
        ) : (
          <AddToCartBtn
            className="text-base"
            product={product}
            selectedCustomFields={!!customOptionsData[product.sku]}
            detail
          />
        )}
      </div>
    </div>
  )
}

StickyProductCard.propTypes = {
  product: PropTypes.object,
}

export default StickyProductCard
