import Text from '@components/ui/Text'
import Icon from '@components/ui/Icon'
import Input from '@components/ui/Input'
import useI18n from '@utils/hooks/useI18n'
import useOnClickOutside from '@utils/hooks/useClose'
import { useRef } from 'react'
import { appConfig } from '@utils/appConfig'
import cn from '@utils/helpers/cn'
import Link from '@components/ui/Link'

const ShareIcon = ({ setIsOpen, source }) => {
  const i18n = useI18n()
  const shareProductRef = useRef(null)
  useOnClickOutside(shareProductRef, () => setIsOpen(false))
  const currentRoute = window !== 'undefined' ? window.location.href : null
  return (
    <div
      className="absolute z-30 w-[400px] border-2 bg-white p-4"
      ref={shareProductRef}
    >
      <div className="flex justify-between pb-3">
        <Text variant="inlineText" className="text-lg font-semibold text-black">
          {i18n.t('Share this product')}
        </Text>
        <span
          className="top-10 z-[11] cursor-pointer font-bold text-gray-400"
          onClick={() => setIsOpen(false)}
        >
          &#10005;
        </span>
      </div>
      <div className="grid grid-cols-3 grid-rows-2 gap-2 pb-4">
        {appConfig.shareLinks.map((keys) => (
          <Link
            className={cn(
              'flex cursor-pointer items-center justify-center rounded border border-[#9d9d9d] p-2 text-center'
            )}
            external
            key={keys.link}
            href={keys.link
              .replace('@url', currentRoute)
              .replace('@title', source?.meta_title || source?.name)
              .replace(
                '@description',
                source?.meta_description || source?.description
              )}
          >
            <span
              className={cn('mr-1 flex h-4 w-4 items-center justify-center', {
                'bg-[#3B5998]': keys.name === 'facebook',
                'bg-white': keys.name === 'xing',
              })}
            >
              <Icon
                icon={`icon-${keys.name}`}
                className={cn('pr-1 text-base', {
                  'pr-0 pt-1 text-white': keys.name === 'facebook',
                  'bg-white ': keys.name === 'xing',
                })}
              />
            </span>
            {keys.title}
          </Link>
        ))}
      </div>
      <div className="flex">
        <div className="relative w-4/5">
          <Input
            type="text"
            className="rounded-l border-[#9D9D9D] text-[#9D9D9D]"
            source
            currentValue={currentRoute}
          />
        </div>
        <input
          type="button"
          onClick={() => navigator.clipboard.writeText(currentRoute)}
          value={i18n.t('Copy Link')}
          className="w-1/5 min-w-fit cursor-pointer rounded-r bg-[#9D9D9D] p-2 text-white"
        />
      </div>
    </div>
  )
}

export default ShareIcon
