import PropTypes from 'prop-types'
import Icon from '@components/ui/Icon'
import cn from '@utils/helpers/cn'

const Arrow = ({
  isSlider,
  onClick,
  left,
  type,
  onMouseOver = () => ({}),
  onMouseOut = () => ({}),
}) => {
  if (onClick && isSlider) {
    return (
      <div className={cn('slide-arrow', left ? 'left-2.5' : 'right-2.5')}>
        {left ? (
          <button
            className={cn(
              'absolute top-1/2 z-[1] flex w-[32px] -translate-y-1/2 cursor-pointer items-center justify-center rounded-md border border-[#ccc] bg-gradient-to-br from-[#f7f7f7] via-[#f4f4f4]  to-[#e3e3e3]',
              type === 'full' ? 'left-0 h-full' : 'left-2.5 h-[42px]',
            )}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          >
            <Icon
              icon="icon-chevron-thin-left"
              className="text-[24px] text-[#4b515a] disabled:text-red-700"
            />
          </button>
        ) : (
          <button
            className={cn(
              'absolute top-1/2 z-[1] flex h-[42px] w-[32px] -translate-y-1/2 cursor-pointer items-center justify-center rounded-md border border-[#ccc] bg-gradient-to-br from-[#f7f7f7] via-[#f4f4f4]  to-[#e3e3e3]',
              type === 'full' ? 'right-0 h-full' : 'right-2.5 h-[42px]',
            )}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          >
            <Icon
              icon="icon-chevron-thin-right"
              className="text-[24px] !text-[#6b727d]"
            />
          </button>
        )}
      </div>
    )
  }
  return null
}

Arrow.propTypes = {
  left: PropTypes.bool,
  type: PropTypes.string,
  isSlider: PropTypes.bool,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
}

export default Arrow
