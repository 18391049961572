import Link from '@components/ui/Link'
import Text from '@components/ui/Text'
import Icon from '@components/ui/Icon'
import useI18n from '@utils/hooks/useI18n'
import localRoute from '@utils/helpers/localRoute'

const Advantages = () => {
  const i18n = useI18n()
  return (
    <>
      <div className="mt-10 w-full rounded-[3px] bg-gradient-to-b from-[#f7f7f7] to-[#e2e2e2] px-[30px] py-[40px] print:hidden">
        <Text
          variant="inlineText"
          className="mb-[30px] block text-[22px] font-semibold text-black"
        >
          {i18n.t('Your Advantages')}
        </Text>
        <ul className="pl-5">
          <li className="flex items-center text-sm font-light text-black">
            <Icon
              className="mr-1 size-4 text-[#332e2e]"
              icon={'icon-check_circle'}
            />
            {i18n.t('more than 12,000 items available immediately')}
          </li>
          <li className="mt-[1.33em] flex items-center text-sm font-light text-black">
            <Icon
              className="mr-1 size-4 text-[#332e2e]"
              icon={'icon-check_circle'}
            />
            {i18n.t('Free shipping within Germany')}
          </li>
          <li className="mt-[1.33em] flex items-center text-sm font-light text-black">
            <Icon
              className="mr-1 size-4 text-[#332e2e]"
              icon={'icon-check_circle'}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: i18n.t(['incl. {{lowestPrice}}'], {
                  lowestPrice: `<a href="${localRoute('best-price')}" title="${i18n.t('Lowest Price Guarantee')}" target="_blank" class="text-[#345581] underline">${i18n.t('Lowest Price Guarantee')}</a>`,
                }),
              }}
            />
          </li>
          <li className="mt-[1.33em] flex items-center text-sm font-light text-black">
            <Icon
              className="mr-1 size-4 text-[#332e2e]"
              icon={'icon-check_circle'}
            />
            {i18n.t('Top brand quality')}
          </li>
        </ul>
      </div>
      <div className="mb-10 mt-5 rounded-[3px] bg-gradient-to-b from-[#f7f7f7] to-[#e2e2e2] p-[30px]">
        <Text
          variant="inlineText"
          className="mb-[30px] block text-[22px] font-semibold text-black"
        >
          {i18n.t('Are you missing product information?')}
        </Text>
        <Text
          variant="inlineText"
          className="block pl-5 text-sm font-light text-black"
        >
          {i18n.t('Please write to us directly')}{' '}
          <Link
            locale
            href="contacts"
            className="text-sm text-[#345581] hover:text-[#3B73CC]"
          >
            {i18n.t('here')} {'>'}
          </Link>
        </Text>
      </div>
    </>
  )
}

export default Advantages
