import cn from '@utils/helpers/cn'
import PropTypes from 'prop-types'
import { useState } from 'react'

const CheckBox = ({
  id,
  active,
  className,
  onClick,
  children,
  isSmall = false,
  name,
  value,
  isDisabled = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(active)
  const handleClick = () => {
    setIsChecked(!isChecked)
    if (onClick) {
      onClick(value)
    }
  }
  return (
    <div className={cn('flex items-center', className)}>
      <label
        className="flex cursor-pointer items-center text-sm font-normal leading-none text-gray-700"
        htmlFor={id}
      >
        <input
          name={name}
          type="checkbox"
          className={cn(
            'mr-2 h-5 w-5 cursor-pointer rounded-xl border accent-[#0D7D09]',
            {
              'h-4 w-4': isSmall,
            },
          )}
          id={id}
          defaultChecked={isChecked}
          defaultValue={value}
          onClick={handleClick}
          disabled={isDisabled}
          {...props}
        />

        {children}
      </label>
    </div>
  )
}

CheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
  children: PropTypes.node,
}

export default CheckBox
