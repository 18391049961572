import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import s from '../ProductPage.module.css'
import Exploded from '@components/Pages/ProductPage/Exploded/Exploded'
import useI18n from '@utils/hooks/useI18n'

const Description = ({ technical, description, manual, exploded_view }) => {
  const titleData = []
  let isFullWidth = true
  if (description?.length > 0 && technical?.length > 0) {
    isFullWidth = false
  }
  if (description?.length > 0) {
    titleData.push('Description')
  }
  if (technical?.length > 0) {
    titleData.push('Technical data')
  }
  if (exploded_view || manual) {
    titleData.push('Download')
  }

  const i18n = useI18n()

  const descriptionData =
    description?.length > 0 ? description[0]?.items[0]?.value || '' : ''

  return (
    <div>
      <div className="mb-10 grid grid-cols-1 gap-12 md:grid-cols-7">
        {titleData.map((data, i) => (
          <div
            key={i}
            className={cn('border-b border-[#E2E2E2]', {
              'lg:hidden': data === 'Download',
              'order-2': data === 'Description',
              'order-1': data === 'Technical data',
              'order-3': data === 'Download',
              'md:order-1': data === 'Description',
              'md:order-2': data === 'Technical data',
              'md:order-3': data === 'Download',
              'col-span-1': data === 'Description',
              'col-span-1': data === 'Technical data',
              'col-span-1': data === 'Download',
              'md:col-span-4': data === 'Description',
              'md:col-span-3': data === 'Technical data',
              'md:col-span-7': isFullWidth,
            })}
          >
            <div
              className={cn(
                'citems-center flex justify-between border-b border-current py-2 text-[#B90202] print:hidden',
              )}
            >
              <p className="my-2 text-sm font-semibold capitalize">
                {i18n.t(data)}
              </p>
            </div>
            {data === 'Download' ? (
              <Exploded
                manual={manual}
                exploded_view={exploded_view}
                className="lg:hidden"
              />
            ) : (
              <div
                className={cn(
                  data === 'Description' ? s.tabs : s.table,
                  'print:hidden',
                )}
                dangerouslySetInnerHTML={{
                  __html: data === 'Description' ? descriptionData : technical,
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
Description.propTypes = {
  manual: PropTypes.string,
  exploded_view: PropTypes.string,
  technical: PropTypes.node,
  description: PropTypes.array,
}

export default Description
