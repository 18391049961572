import cn from '@utils/helpers/cn'
import Image from '@components/ui/Image'
import Icon from '@components/ui/Icon'

const Thumbnail = ({ gallery, activeSlider, clear, hover, alt }) => {
  return (
    <ul className="relative mr-[30px] hidden grid-flow-col grid-rows-6 gap-1 lg:grid">
      {gallery?.map((media, index) => (
        <li
          className={cn(
            'm-[5px] size-[80px] cursor-pointer',
            index === activeSlider
              ? 'li-shadow border-2 border-[#D81100]'
              : 'border border-[#e2e2e2]',
          )}
          onClick={() => hover(index)}
          onMouseOver={() => hover(index)}
          onMouseLeave={clear}
          key={index}
        >
          <div className="relative size-[80px] cursor-pointer overflow-hidden pb-4 opacity-90 mix-blend-multiply hover:opacity-100">
            {media?.video && (
              <Icon
                icon="icon-play"
                className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 text-3xl text-white"
              />
            )}
            {media?.orbitvu && (
              <Image
                src="/icons-360-view.png"
                alt="360 view"
                width={30}
                height={30}
                className="absolute right-2 top-2 z-50"
              />
            )}
            <Image
              alt={alt}
              src={media.image}
              width={80}
              height={80}
              className={cn(
                'max-h-[78px] max-w-[78px] cursor-pointer object-contain',
                {
                  'aspect-square object-cover': media?.video,
                },
              )}
            />
          </div>
        </li>
      ))}
    </ul>
  )
}

export default Thumbnail
