import CheckBox from '@components/ui/CheckBox'
import Spinner from '@components/ui/Spinner'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { productService } from '@utils/magento2'
import formatPrice from '@utils/helpers/price'
import { setCustomOption, clearCustomOptions } from '@features/cart/cartSlice'
import { appConfig } from '@utils/appConfig'

const CustomOptions = ({ options, productSku }) => {
  const dispatch = useDispatch()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const countryCode = useSelector((state) => state.store.country.code)
  const selectedOptions = useSelector((state) => state.cart.customOptions || {})
  const countryData = appConfig.deliveryCountry.find(
    (item) => item.code === countryCode.toLowerCase(),
  )
  const priceSuffix =
    countryData?.salesPriceAttributeSuffix || countryCode.toLowerCase()

  useEffect(() => {
    const fetchProducts = async (skus) => {
      try {
        const data = await productService.getProductsBySku(skus)
        setProducts(data?.products?.map((product) => product._source) || [])
        setDefaultOption()
      } catch (error) {
        console.error('Error:', error)
      } finally {
        setLoading(false)
      }
    }
    const productsSku = []
    options?.forEach((option) => {
      option?.values?.forEach((value) => {
        productsSku.push(value.sku)
      })
    })
    if (productsSku.length > 0) {
      setLoading(true)
      fetchProducts(productsSku)
    }
    return () => {
      dispatch(clearCustomOptions())
    }
  }, [])

  const singleProduct = (sku, title) => {
    const product = products.find(
      (product) => product.sku.toUpperCase() === sku.toUpperCase(),
    )
    if (product) {
      return {
        name: product.name,
        price: product[`sales_price_${priceSuffix}`] || product.price,
      }
    }
    return { name: title, price: null }
  }

  const setDefaultOption = () => {
    for (const option_list of options) {
      let defaultOption = null
      let defaultPrice = -1
      for (var option of option_list.values) {
        const optionPrice = singleProduct(option.sku, option.title)?.price || 0
        if (
          optionPrice !== '' &&
          (defaultPrice === -1 ||
            parseFloat(optionPrice) < parseFloat(defaultPrice))
        ) {
          defaultOption = option
          defaultPrice = parseFloat(optionPrice)
        }
      }
      if (defaultOption) {
        dispatch(
          setCustomOption({
            sku: productSku,
            id: option_list.option_id,
            value: defaultOption.option_type_id,
            price: defaultPrice,
          }),
        )
      }
    }
  }
  return (
    <>
      {options?.map((option) => {
        return (
          <div className="mb-5" key={option.option_id}>
            <h4 className="text-lg font-bold">{option.title}</h4>
            {loading ? (
              <Spinner />
            ) : (
              option?.values?.map((value) => {
                return (
                  <div
                    className="relative my-[10px] mt-5 flex items-center gap-4"
                    key={value.option_type_id}
                  >
                    <CheckBox
                      id={`checkbox-${value.option_type_id}`}
                      name={value.option_type_id}
                      label={value.title}
                      active={
                        selectedOptions &&
                        selectedOptions[productSku]?.value ===
                          value.option_type_id
                      }
                      onChange={() =>
                        dispatch(
                          setCustomOption({
                            sku: productSku,
                            id: option.option_id,
                            value: value.option_type_id,
                            price:
                              singleProduct(value.sku, value.title)?.price || 0,
                          }),
                        )
                      }
                    >
                      <span className="text-base font-semibold">
                        {singleProduct(value.sku, value.title).name}
                        {singleProduct(value.sku, value.title).price &&
                          ` (+ ${formatPrice(singleProduct(value.sku, value.title).price)})`}
                      </span>
                    </CheckBox>
                  </div>
                )
              })
            )}
          </div>
        )
      })}
    </>
  )
}

export default CustomOptions
