import AddToWishlist from '@components/ui/AddToWishlist'
import AddToCartBtn from '@components/ui/AddtoCartBtn/AddtoCartBtn'
import DeliveryTime from '@components/ui/DeliveryTime'
import Icon from '@components/ui/Icon'
import Price from '@components/ui/Price'
import RRP from '@components/ui/RRP'
import Stars from '@components/ui/Stars'
import Text from '@components/ui/Text'
import cn from '@utils/helpers/cn'
import formatPrice from '@utils/helpers/price'
import useI18n from '@utils/hooks/useI18n'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import ShareProduct from '@components/ui/ShareProduct'
import Quantity from '@components/ui/Quantity'
import CustomOptions from '@components/Pages/ProductPage/CustomOptions'
import requestItem from '@utils/helpers/requestItem'
import Modal from '@components/ui/Modal'
import Image from '@components/ui/Image'
import localRoute from '@utils/helpers/localRoute'
import MPBBlockDynamic from '@components/MagentoPageBuilder/blocks/MPBBlockDynamic'

const LeasingButton = dynamic(
  () => import('@components/ui/LeasingButton/LeasingButton'),
  { ssr: false },
)
const PrintProduct = dynamic(
  () => import('@components/Pages/ProductPage/PrintProduct/PrintProduct'),
  { ssr: false },
)

const SidesForm = dynamic(
  () => import('@components/Pages/ProductPage/SidesForm'),
  { ssr: false },
)

const EnergyLabel = dynamic(
  () => import('@components/Pages/ProductPage/EnergyLabel'),
  { ssr: false },
)

const ProductInfo = ({ source, scrollToReview, className }) => {
  const i18n = useI18n()
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [qty, setQty] = useState(1)
  const [arModal, setArModal] = useState(false)
  const [sidesForm, setSidesForm] = useState(false)
  const { country, language } = useSelector((state) => state.store)
  const isMobile = useSelector((state) => state.ui.isMobile)
  const [isPrint, setIsPrint] = useState(false)
  const selectedCountry = source?.deliverytime?.find(
    (item) => item.country_id === country.code.toUpperCase(),
  )
  const customOptionsData = useSelector(
    (state) => state.cart.customOptions || {},
  )

  const productLabels = useSelector(
    (state) => state.attribute?.byCode?.product_label,
  )

  const shareBtn = async () => {
    if (navigator && navigator.share) {
      await navigator.share({
        title: source?.name,
        url: typeof window !== 'undefined' ? window.location.href : null,
      })
    } else {
      setIsOpen(true)
    }
  }
  const printProduct = async () => {
    setIsPrint(true)

    setTimeout(() => {
      const divContents = document.getElementById('print-product').innerHTML
      const printWindow = window.open('', '_blank', 'height=600,width=800')
      printWindow.document.write(
        '<!DOCTYPE html><html><head> <script src="https://unpkg.com/@tailwindcss/browser@4"></script></head><body>',
      )
      printWindow.document.write(divContents)
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      setTimeout(() => {
        printWindow.print()
        setIsPrint(false)
      }, 2000)
    }, 100)
  }

  const showArView = () => {
    if (isMobile) {
      window.open(source.adobeaero_link, '_blank')
    } else {
      setArModal(true)
    }
  }

  const extraDiscount = () => {
    const discountLabels =
      productLabels?.options?.filter((l) =>
        l.label?.startsWith('pricebox_discount_'),
      ) || []
    if (discountLabels.length > 0) {
      let discount = 0
      discountLabels.forEach((l) => {
        if (source.product_label?.includes(parseInt(l.value))) {
          discount = parseInt(l.label.replace('pricebox_discount_', '')) || 0
        }
      })
      return discount
    }
    return 0
  }

  return (
    <div className={cn('w-full', className)}>
      <div className="flex flex-col items-start pb-4 text-left">
        <Text
          variant="heading"
          className="mb-[5px] text-[22px] font-black leading-8 max-lg:text-[30px]"
        >
          {source?.name}
        </Text>
        <Stars
          reviewCount={source?.average_review_count}
          rating={Math.ceil(source?.average_review)}
          type="detailed"
          scrollToReview={scrollToReview}
        />
        <Text variant="body" className="text-sm font-light text-[#332e2e]">
          {i18n.t('SKU')} {source?.sku}
        </Text>
        {source?.meta_description && (
          <Text variant="body" className="text-sm font-light text-[#332e2e]">
            {source?.meta_description}
          </Text>
        )}
      </div>
      {source?.adobeaero_qr_export &&
        source.adobeaero_qr_export !== 'no_selection' && (
          <div className="mb-4">
            <button
              className="flex items-center rounded border border-[#1161B1] bg-[#E3F3FF] px-3 py-2 text-sm font-semibold text-[#1161B1]"
              onClick={showArView}
            >
              <Image
                src="/icons/view_in_ar.svg"
                width={24}
                height={24}
                alt={i18n.t('Glimpse into your kitchen')}
                className="mr-2"
              />
              {i18n.t('Glimpse into your kitchen')}
            </button>
            {arModal && (
              <Modal closeCallback={() => setArModal(false)}>
                <Text
                  variant="body"
                  className="mt-4 flex items-center text-xl font-semibold"
                >
                  <Image
                    src="/icons/view_in_ar.svg"
                    width={24}
                    height={24}
                    alt={i18n.t('Glimpse into your kitchen')}
                    className="mr-2"
                  />
                  {i18n.t('Glimpse into your kitchen')}
                </Text>
                <div className="mb-2 mt-8 flex items-center justify-center">
                  <Image
                    src={`${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/catalog/product${source.adobeaero_qr_export}`}
                    width={300}
                    height={300}
                    alt={i18n.t('Glimpse into your kitchen')}
                  />
                </div>
              </Modal>
            )}
          </div>
        )}
      {source?.custom_options?.length > 0 && (
        <div className="mb-5">
          <CustomOptions
            options={source.custom_options}
            productSku={source.sku}
            key={source.sku}
          />
        </div>
      )}
      {source?.second_choice && Boolean(Number(source.second_choice)) ? (
        <div className="second-choice-cms">
          <MPBBlockDynamic identifier="b-ware-detailseite" />
        </div>
      ) : null}
      <div className="bg-[#f7f7f7] px-[30px] pb-0.5 pt-10 print:px-0 print:pt-2">
        <div className="mb-5 flex flex-col print:flex-row print:gap-5">
          <div className="relative">
            <Price
              price={
                parseFloat(source?.storePrice?.price || 0) +
                parseFloat(customOptionsData[source.sku]?.price || 0)
              }
              prefix={router?.query?.locales || null}
              type="productPage"
            />
            {extraDiscount() > 0 && (
              <div className="pt-2.5 text-base text-[#9D9D9D]">
                <span className="line-through">
                  {parseInt(
                    (parseFloat(source?.storePrice?.price || 0) +
                      parseFloat(customOptionsData[source.sku]?.price || 0)) /
                      (1 - extraDiscount() / 100),
                  ) + 0.99}
                </span>{' '}
                {i18n.t('Discount')}{' '}
                <span className="font-bold text-[#E31100]">
                  -{extraDiscount()}%
                </span>
              </div>
            )}
            {source?.energy_efficiency_class && (
              <EnergyLabel
                value={source?.energy_efficiency_class}
                energyLabel={source?.energy_label_export}
              />
            )}
          </div>
          {source?.storePrice?.rrp && (
            <RRP
              oldPrice={source?.storePrice.rrp}
              type="productPage"
              prefix={router?.query?.locales || null}
              className="text-base"
            />
          )}
        </div>
        {source?.isCustomOptionsRequired && !customOptionsData[source.sku] && (
          <div className="mb-4 flex items-center">
            <Icon icon="icon-info" className="text-base text-[#D81100]" />
            <Text
              variant="inlineText"
              className=" ml-2 text-base text-[#D81100]"
            >
              {i18n.t('Please choose a variant')}
            </Text>
          </div>
        )}
        <DeliveryTime
          deliveryTimeMin={selectedCountry?.deliverytime_min}
          deliveryTimeMax={selectedCountry?.deliverytime_max}
          deliveryTimeUnit={selectedCountry?.deliverytime_unit}
        />
        <div className="flex flex-wrap items-center justify-between pt-1 max-md:pt-2">
          <DeliveryTime
            deliveryTimeUnit={'included'}
            className="text-sm"
            href={'best-price'}
          />
          <div className="flex items-center justify-end">
            <Text variant="inlineText" className="mr-2 text-base">
              {i18n.t('Quantity')}:
            </Text>
            <Quantity current={qty} callback={setQty} />
          </div>
        </div>
        {(source?.second_choice && Boolean(Number(source.second_choice))) ||
        requestItem(source) ? (
          <div className="flex py-4 print:hidden">
            <button
              onClick={() => setSidesForm(true)}
              className={cn(
                'mr-3 flex w-full items-center justify-center rounded border border-[#298400] bg-gradient-to-b from-[#46C154] to-[#097700] px-1 py-3 text-lg font-semibold text-white outline-none hover:bg-gradient-to-b hover:from-[#33a741] hover:to-[#0C5F05]',
                requestItem(source) ? '' : 'pointer-events-none opacity-50',
              )}
            >
              {i18n.t('Submit request')}
            </button>
            {sidesForm && (
              <Modal closeCallback={() => setSidesForm(false)}>
                <SidesForm
                  sku={source.sku}
                  closeCallback={() => setSidesForm(false)}
                />
              </Modal>
            )}
            <AddToWishlist id={source.id} className="h-auto w-16" />
          </div>
        ) : (
          <>
            {' '}
            <div className="flex pt-4 print:hidden">
              <AddToCartBtn
                className="mr-3 text-lg"
                product={source}
                qty={qty}
                selectedCustomFields={!!customOptionsData[source.sku]}
                detail
              />
              <AddToWishlist id={source.id} className="h-auto w-16" />
            </div>
            <div className="mb-[30px] mt-2.5">
              {selectedCountry?.deliverytime_unit && (
                <LeasingButton
                  price={source?.storePrice?.price}
                  product={source}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col flex-wrap pt-4 text-xs text-gray-400 max-md:flex-col max-md:gap-3 md:flex-row md:justify-between">
        <span className="flex cursor-pointer">
          <span
            className="flex flex-wrap items-center gap-1 pr-2 hover:text-red-500 print:hidden"
            onClick={printProduct}
          >
            <Icon icon="icon-printer1" />
            <Text variant="inlineText">{i18n.t('Print')}</Text>
          </span>
          <button
            className="pointer-events-auto flex items-center gap-1 hover:text-red-500"
            onClick={shareBtn}
          >
            <Icon icon="icon-share" />
            <Text variant="inlineText">{i18n.t('Share')}</Text>
          </button>
        </span>
        {isOpen && <ShareProduct source={source} setIsOpen={setIsOpen} />}
        <Text
          variant="inlineText"
          className="mt-1 flex md:mt-0"
          dangerouslySetInnerHTML={{
            __html: i18n.t(
              [
                '* gross price incl. {{tax}} VAT.: {{price}}, incl. {{shipping}}',
              ],
              {
                tax: `${source?.storePrice.taxRate}%`,
                price: formatPrice(
                  source?.storePrice.grossPrice,
                  false,
                  true,
                  router?.query?.locales || null,
                ),
                shipping: `<a href="${localRoute('shipping-cost')}" title="${i18n.t('Shipping costs')}" target="_blank" class="text-[#345581] underline hover:text-[#3B73CC] ml-1">${i18n.t('Shipping costs')}</a>`,
              },
            ),
          }}
        />
      </div>
      {isPrint && (
        <PrintProduct
          className="hidden print:block"
          product={source}
          selectedCountry={selectedCountry}
          country={country}
          language={language}
        />
      )}
    </div>
  )
}

ProductInfo.propTypes = {
  source: PropTypes.object,
  scrollToReview: PropTypes.func,
}

export default ProductInfo
