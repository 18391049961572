import Advantages from '@components/Pages/ProductPage/Advantages'
import Description from '@components/Pages/ProductPage/Description'
import DesktopStickyProduct from '@components/Pages/ProductPage/DesktopStickyProduct'
import Exploded from '@components/Pages/ProductPage/Exploded'
import ProductSwiper from '@components/Pages/ProductPage/ProductSwiper'
import Thumbnail from '@components/Pages/ProductPage/Thumbnail'
import Certifications from '@components/blocks/Layout/Layout-ui/Certifications/Certifications'
import AddToCartBtn from '@components/ui/AddtoCartBtn/AddtoCartBtn'
import Container from '@components/ui/Container'
import Price from '@components/ui/Price'
import Link from '@components/ui/Link'
import Icon from '@components/ui/Icon'
import Text from '@components/ui/Text'
import requestItem from '@utils/helpers/requestItem'
import ProductInfo from '@components/ui/ProductInfo'
import RRP from '@components/ui/RRP'
import StickyProductCard from '@components/ui/StickyProductCard'
import cn from '@utils/helpers/cn'
import useI18n from '@utils/hooks/useI18n'
import { ProductJsonLd } from 'next-seo'
import dynamic from 'next/dynamic'
import Image from '@components/ui/Image'
import { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react'
import { useSelector } from 'react-redux'
import ConfigContext from '@utils/context/config'
import { useContext } from 'react'

const ProductLabel = dynamic(() => import('@components/ui/ProductLabel'), {
  ssr: false,
})
const RelatedCategories = dynamic(
  () => import('@components/Pages/ProductPage/RelatedCategories'),
  {
    ssr: false,
  },
)
const Breadcrumbs = dynamic(() => import('@components/ui/Breadcrumbs'), {
  ssr: false,
})

const Review = dynamic(() => import('@components/Pages/ProductPage/Review'), {
  ssr: false,
})
const RelatedProducts = dynamic(
  () => import('@components/Pages/ProductPage/RelatedProducts'),
  {
    ssr: false,
  },
)

// Memoize child components for better performance
const MemoizedThumbnail = memo(Thumbnail)
const MemoizedProductSwiper = memo(ProductSwiper)
const MemoizedProductInfo = memo(ProductInfo)
const MemoizedStickyProductCard = memo(StickyProductCard)

// Create a separate component for product images section
const ProductImages = memo(
  ({
    source,
    activeSlider,
    handleMouseOver,
    navigateClear,
    setActiveSlider,
  }) => {
    return (
      <>
        <div className="flex w-full print:hidden">
          <MemoizedThumbnail
            gallery={imageList(source)}
            activeSlider={activeSlider}
            hover={handleMouseOver}
            clear={navigateClear}
            alt={source?.name}
          />
          <div className="flex h-auto flex-1 justify-center overflow-hidden">
            <div className="relative w-full max-w-[600px]">
              <div className="pointer-events-none absolute bottom-0 right-0 top-auto flex flex-col items-end justify-start sm:top-0">
                {source.product_label?.length > 0 && (
                  <ProductLabel labels={source.product_label} key={source.id} />
                )}
              </div>
              <MemoizedProductSwiper
                gallery={imageList(source)}
                name={source?.name}
                activeSlider={activeSlider}
                setActiveSlider={setActiveSlider}
              />
            </div>
          </div>
        </div>
        <Image
          src={imageList(source)[0].image}
          width={400}
          height={400}
          alt="print image"
          className="hidden min-h-[350px] min-w-[350px] print:block"
        />
      </>
    )
  },
)

ProductImages.displayName = 'ProductImages'

// Move imageList function outside component and memoize its result
const imageList = (source) => {
  let imageListData = []
  if (source?.bynder_images_nested?.length > 0) {
    imageListData = source.bynder_images_nested.map((item) => ({
      image: item.url,
    }))
  } else if (source?.media_gallery?.length > 0) {
    imageListData = source.media_gallery.map((item) => ({
      image: `${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/catalog/product/${item.image}`,
    }))
  } else if (source?.image) {
    imageListData = [
      {
        image: `${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/catalog/product/${source.image}`,
      },
    ]
  } else {
    imageListData = [
      {
        image: '/logo_placeholder.png',
      },
    ]
  }

  if (source?.orbitvu_id) {
    const orbitvu = {
      image: `https:${source.orbitvu_thumbnail}`,
      orbitvu: `https:${source.orbitvu_script.replace('/script', '/view')}`,
    }
    imageListData.splice(1, 0, orbitvu)
  }

  if (source?.videos?.length > 0 && source?.videos_data) {
    const videoList = JSON.parse(source.videos_data)?.map((item) => ({
      image: item.url.includes('youtube')
        ? `https://img.youtube.com/vi/${item.url.split('embed/')[1]?.split('?')[0]}/default.jpg`
        : `${process.env.NEXT_PUBLIC_IMG_ENDPOINT}media/${source.image}`,
      video: item.url,
    }))
    imageListData.splice(1, 0, ...videoList)
  }

  return imageListData
}

const ProductPage = ({ source, language, country, currency }) => {
  // Hooks
  const i18n = useI18n()
  const reviewRef = useRef(null)
  const { isMobile } = useSelector((state) => state.ui)
  const [activeSlider, setActiveSlider] = useState(0)
  const [activeTab, setActiveTab] = useState(
    source.accessories?.length > 0
      ? i18n.t('Accessories')
      : i18n.t('Spare Parts'),
  )

  const categoryPath = useSelector((state) => state.category.beforeCategoryPath)
  const customOptionsData = useSelector(
    (state) => state.cart.customOptions || {},
  )
  const { domain } = useContext(ConfigContext)

  let timeoutId = null

  // Memoize values that don't need to change on every render
  const memoizedImageList = useMemo(() => imageList(source), [source])
  const memoizedProductJsonData = useMemo(
    () => ({
      productName: source?.meta_title,
      images: memoizedImageList.map((item) => item.image),
      description: source?.meta_description,
      brand: 'GGM Gastro',
      offers: {
        price: parseFloat(source?.storePrice.grossPrice).toFixed(2),
        priceCurrency: currency,
        itemCondition: 'https://schema.org/NewCondition',
        availability: 'https://schema.org/InStock',
        url: `${domain}/${language}-${country}-${currency}/${source.slug}`,
      },
      mpn: source?.sku,
      aggregateRating: source?.average_review
        ? {
            ratingValue: source?.average_review,
            reviewCount: source?.average_review_count,
          }
        : false,
    }),
    [source, currency, language, country, memoizedImageList, domain],
  )

  // Memoize event handlers
  const handleMouseOver = useCallback((index) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      setActiveSlider(index)
    }, 300)
    return () => clearTimeout(timeoutId)
  }, [])

  const navigateClear = useCallback(() => {
    clearTimeout(timeoutId)
  }, [])

  const scrollToReview = useCallback(() => {
    reviewRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [])

  const handleTabChange = useCallback((tabName) => {
    setActiveTab(tabName)
  }, [])

  // Handle Intercom class
  useEffect(() => {
    const addIntercomClass = () => {
      const intercom = document?.getElementsByClassName(
        'intercom-lightweight-app-launcher',
      )[0]
      if (intercom) {
        intercom.classList.add('product-page')
        return true
      }
      return false
    }

    if (typeof window !== 'undefined') {
      if (!addIntercomClass()) {
        const timeoutId = setTimeout(addIntercomClass, 3000)
        return () => clearTimeout(timeoutId)
      }
    }

    return () => {
      const intercom = document?.getElementsByClassName(
        'intercom-lightweight-app-launcher',
      )[0]
      intercom?.classList.remove('product-page')
    }
  }, [source?.sku])

  return (
    <Container>
      <div className="hidden justify-between print:flex">
        <Image
          src={`${process.env.NEXT_PUBLIC_IMG_ENDPOINT}pub/media/wysiwyg/S_ggmgastro_INT_logo.png`}
          alt="GGM logo"
          width={215}
          height={106}
        />
        <Certifications />
      </div>

      <ProductJsonLd {...memoizedProductJsonData} />

      <section className="relative mt-5">
        <DesktopStickyProduct>
          <MemoizedStickyProductCard product={source} />
        </DesktopStickyProduct>

        <Breadcrumbs
          className="pt-5"
          categoryPath={categoryPath}
          categoryId={categoryPath ? null : source?.category_ids}
          lastStep={source?.name}
          key={source?.sku}
        />

        <div className="mb-[50px] flex flex-col flex-wrap justify-between lg:flex-row lg:pt-10 print:flex-row">
          <div className="flex w-full flex-col max-lg:mb-5 lg:w-3/5 lg:pr-4 xl:w-[66%] print:w-1/2">
            <ProductImages
              source={source}
              activeSlider={activeSlider}
              handleMouseOver={handleMouseOver}
              navigateClear={navigateClear}
              setActiveSlider={setActiveSlider}
            />
            <p className="my-[14px] px-3 text-left text-sm font-light text-[#9d9d9d]">
              {i18n.t(['* The picture may slightly differ from the original.'])}
            </p>
          </div>

          <div className="w-full max-sm:px-4 lg:w-2/5 xl:w-[34%] print:w-1/2">
            <MemoizedProductInfo
              source={source}
              scrollToReview={scrollToReview}
            />
          </div>
        </div>
      </section>

      <section className="mb-10">
        <div className="flex w-full flex-wrap">
          <div className="w-full lg:w-[66%]">
            <div className="flex w-full gap-2.5 border-b border-black print:hidden">
              {source.accessories.length > 0 && (
                <div
                  className={cn(
                    'flex w-[23.26%] cursor-pointer select-none items-center justify-center border border-[#4B515A] bg-gradient-to-t from-black to-[#7C8490] p-2.5 text-base leading-none text-white hover:bg-gradient-to-b max-lg:w-1/2',
                    {
                      'pointer-events-none bg-gradient-to-b':
                        activeTab === i18n.t('Accessories'),
                      'max-lg:w-[calc(50%-5px)]':
                        source.spare_parts.length === 0,
                      'w-[calc(23.26%+3px)] max-lg:w-[calc(50%-10px)]':
                        source.product_links.length === 1,
                    },
                  )}
                  onClick={(e) => handleTabChange(e.target.innerText)}
                >
                  {i18n.t('Accessories')}
                </div>
              )}
              {source.spare_parts.length > 0 && (
                <div
                  className={cn(
                    'flex w-[23.26%] cursor-pointer select-none items-center justify-center border border-[#4B515A] bg-gradient-to-t from-black to-[#7C8490] p-2.5 text-base leading-none text-white hover:bg-gradient-to-b max-lg:w-1/2 print:hidden',
                    {
                      'pointer-events-none bg-gradient-to-b':
                        activeTab === i18n.t('Spare Parts'),
                      'max-lg:w-[calc(50%-5px)]':
                        source.accessories.length === 0,
                      'w-[calc(23.26%+3px)] max-lg:w-[calc(50%-10px)]':
                        source.spare_parts.length === 1,
                    },
                  )}
                  onClick={(e) => handleTabChange(e.target.innerText)}
                >
                  {i18n.t('Spare Parts')}
                </div>
              )}
            </div>
            {(source.spare_list_products || source.accessories) && (
              <RelatedProducts
                products={
                  activeTab === i18n.t('Accessories')
                    ? source.accessories
                    : source.spare_parts
                }
                name={
                  activeTab === i18n.t('Accessories')
                    ? 'accessories'
                    : 'spare parts'
                }
              />
            )}
            <Description
              technical={source?.technical_description}
              description={source?.description}
              manual={source?.manual}
              exploded_view={source?.exploded_view}
            />
          </div>
          <div className="w-full lg:w-[34%] lg:pl-7 lg:pt-[10px] print:hidden">
            {(source?.exploded_view || source?.manual) && (
              <div className="w-full max-lg:hidden">
                <Exploded
                  manual={source?.manual}
                  exploded_view={source?.exploded_view}
                />
              </div>
            )}
            <div className="group relative flex w-full bg-[#e3f3ff] px-[30px] py-[40px] lg:mt-7">
              <Image
                src="/icons/tuv100.svg"
                alt="TUV"
                width={100}
                height={100}
                className="mr-4"
              />
              <div className="flex h-full min-h-[100px] flex-col justify-between">
                <Text
                  variant="inlineText"
                  className="mb-2 block text-[22px] font-semibold text-black"
                >
                  {i18n.t('Your reliable partner')}
                </Text>
                <ul>
                  <li className="flex items-center text-sm font-light text-black">
                    <Icon
                      className="mr-1 size-4 text-[#332e2e]"
                      icon={'icon-check_circle'}
                    />
                    {i18n.t(['Price/performance: Grade "very good"'])}
                  </li>
                  <li className="mt-3 flex items-center text-sm font-light text-black">
                    <Icon
                      className="mr-1 size-4 text-[#332e2e]"
                      icon={'icon-check_circle'}
                    />
                    {i18n.t(['Customer satisfaction: Grade "very good"'])}
                  </li>
                </ul>
              </div>
              <div className="absolute top-[150px] z-50 hidden w-[90%] bg-white p-8 drop-shadow group-hover:block">
                <Text
                  variant="inlineText"
                  className="mb-2 block text-lg font-semibold text-black"
                >
                  {i18n.t('TÜV Saarland award')}
                </Text>
                <Text
                  variant="inlineText"
                  className="mb-2 block text-base text-black"
                >
                  {i18n.t(
                    'In an official analysis by TÜV Saarland Germany, we were awarded top marks for',
                  )}{' '}
                  <strong className="font-semibold">
                    {i18n.t('price/performance')}
                  </strong>{' '}
                  {i18n.t('and')}{' '}
                  <strong className="font-semibold">
                    {i18n.t(['customer satisfaction.'])}
                  </strong>{' '}
                  {i18n.t([
                    'Our employees do their best every day to offer you an excellent shopping experience.',
                  ])}
                </Text>
                <div className="my-4 flex items-center justify-between">
                  <Image
                    src="/tuv_sat_1.png"
                    alt="TUV"
                    width={150}
                    height={78}
                  />
                  <Image
                    src="/tuv_aw_1.png"
                    alt="TUV"
                    width={150}
                    height={78}
                  />
                </div>
                <Text
                  variant="inlineText"
                  className="mb-2 block text-sm text-black"
                >
                  {i18n.t([
                    '* These certificates have been awarded on the basis of a representative customer satisfaction survey in Germany',
                  ])}
                </Text>
              </div>
            </div>
            <Advantages />
          </div>
        </div>
        {!isMobile && <RelatedCategories categories={source?.category} />}
        <Review innerRef={reviewRef} id={source.id} />
        <div className="fixed bottom-[calc(60px+env(safe-area-inset-bottom,10px)/2)] left-0 z-[9] flex w-full items-center justify-between border-y border-[#c7c7c7] bg-white px-[15px] py-[5px] lg:hidden print:hidden">
          <div className="mr-1.5 flex grow flex-col py-1">
            <div>
              <Price
                price={source?.storePrice?.price}
                className="flex-col"
                type="productPageSticky"
              />
            </div>
            <RRP oldPrice={source?.storePrice?.rrp} />
          </div>
          <div className="m-0 flex flex-nowrap">
            {(source?.second_choice && Boolean(Number(source.second_choice))) ||
            requestItem(source) ? (
              <Link
                href={requestItem(source) ? requestItem(source) : 'best-price'}
                locale
                className="mr-3 flex w-full items-center justify-center rounded border border-[#298400] bg-gradient-to-b from-[#46C154] to-[#097700] px-1 py-3 text-lg font-semibold text-white outline-none hover:bg-gradient-to-b hover:from-[#33a741] hover:to-[#0C5F05]"
              >
                {i18n.t('Submit request')}
              </Link>
            ) : (
              <AddToCartBtn
                className="text-base"
                product={source}
                selectedCustomFields={!!customOptionsData[source.sku]}
                detail
              />
            )}
          </div>
        </div>
      </section>
    </Container>
  )
}

export default memo(ProductPage)
