import Text from '@components/ui/Text'
import cn from '@utils/helpers/cn'
import useI18n from '@utils/hooks/useI18n'
import PropTypes from 'prop-types'
import { useState, useMemo, useCallback } from 'react'

const Stars = ({
  type = 'normal',
  reviewCount = 0,
  rating = 0,
  setRating,
  scrollToReview,
  className = '',
}) => {
  const [hover, setHover] = useState(0)
  const i18n = useI18n()

  const isDisabled = useMemo(
    () =>
      ['detailed', 'cart', 'wishlist', 'normal', 'mini', 'comment'].includes(
        type,
      ),
    [type],
  )

  const handleMouseEnter = useCallback(
    (index) => !isDisabled && setHover(index),
    [isDisabled],
  )
  const handleMouseLeave = useCallback(() => setHover(rating), [rating])
  const handleClick = useCallback(
    (index) => !isDisabled && setRating(index),
    [isDisabled, setRating],
  )
  const handleDoubleClick = useCallback(() => {
    if (!isDisabled) {
      setRating(0)
      setHover(0)
    }
  }, [isDisabled, setRating])

  const starSize = useMemo(
    () =>
      ({
        filter: 20,
        detailed: 20,
        review: 32,
        normal: 16,
        cart: 16,
        wishlist: 16,
        mini: 16,
        comment: 16,
      })[type] || 16,
    [type],
  )

  const renderStarRating = useMemo(() => {
    const activeValue = hover || rating
    const isInteractive = typeof setRating === 'function'

    const starColor = (starIndex) => {
      const isHighlighted = starIndex <= activeValue
      return isHighlighted
        ? type === 'filter'
          ? 'text-black'
          : 'text-yellow-400'
        : 'text-[#ccc]'
    }

    return (
      <div
        className="flex"
        role={isInteractive ? 'radiogroup' : 'presentation'}
        aria-label={isInteractive ? 'Rating stars' : `${rating} out of 5 stars`}
      >
        <svg
          width={starSize * 5 + 4}
          height={starSize}
          viewBox={`0 0 ${120 + 4} 24`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <path
              id="star-path"
              d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
            />
          </defs>

          {Array.from({ length: 5 }, (_, index) => {
            const starIndex = index + 1

            return (
              <g
                key={starIndex}
                transform={`translate(${index * 24}, 0)`}
                className={starColor(starIndex)}
                onClick={
                  isInteractive ? () => handleClick(starIndex) : undefined
                }
                onDoubleClick={isInteractive ? handleDoubleClick : undefined}
                onMouseEnter={
                  isInteractive ? () => handleMouseEnter(starIndex) : undefined
                }
                onMouseLeave={isInteractive ? handleMouseLeave : undefined}
                role={isInteractive ? 'radio' : 'presentation'}
                aria-checked={
                  isInteractive ? activeValue === starIndex : undefined
                }
                aria-label={isInteractive ? `${starIndex} star` : undefined}
                tabIndex={isInteractive ? 0 : undefined}
                style={isInteractive ? { cursor: 'pointer' } : undefined}
              >
                <use href="#star-path" fill="currentColor" />
              </g>
            )
          })}
        </svg>
      </div>
    )
  }, [
    hover,
    rating,
    type,
    starSize,
    setRating,
    handleClick,
    handleDoubleClick,
    handleMouseEnter,
    handleMouseLeave,
  ])

  return (
    <div
      className={cn(
        'flex min-h-[20px] w-full items-center justify-start',
        className,
      )}
      onClick={scrollToReview || null}
    >
      {renderStarRating}
      {(type === 'detailed' || type === 'mini') && (
        <Text
          variant="inlineText"
          className="hidden cursor-pointer pl-1 text-xs text-[#1161B1] sm:block"
        >
          {reviewCount > 0
            ? `${reviewCount} ${i18n.t('Review')}`
            : i18n.t('Rate now')}
        </Text>
      )}
    </div>
  )
}

Stars.propTypes = {
  type: PropTypes.oneOf([
    'normal',
    'filter',
    'review',
    'detailed',
    'cart',
    'wishlist',
    'mini',
    'comment',
  ]),
  reviewCount: PropTypes.number,
  rating: PropTypes.number,
  setRating: PropTypes.func,
  scrollToReview: PropTypes.func,
  className: PropTypes.string,
}

export default Stars
