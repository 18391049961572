import PropTypes from 'prop-types'
import cn from '@utils/helpers/cn'
import Link from '@components/ui/Link'
import useI18n from '@utils/hooks/useI18n'
import requestItem from '@utils/helpers/requestItem'
import { actionAddToCart } from '@features/cart/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setMicroCart } from '@features/ui/uiSlice'
import { setActiveItemCategory } from '@features/seo/seoSlice'
const AddToCartBtn = ({
  className,
  isIcon = true,
  product,
  qty = 1,
  selectedCustomFields = false,
  detail = false,
  category = '',
  ...props
}) => {
  const dispatch = useDispatch()
  const i18n = useI18n()

  const { loading } = useSelector((state) => state.cart)
  const { microCart } = useSelector((state) => state.ui)
  const country = useSelector((state) => state.store?.country?.code || 'DE')

  const salesBlacklistAttribute = useSelector(
    (state) =>
      state?.attribute?.byCode?.sales_blacklist?.options?.find(
        (item) => item.label.toLowerCase() === country.toLowerCase(),
      )?.value || null,
  )

  const isBlacklisted = () => {
    return (
      product?.sales_blacklist?.includes(parseInt(salesBlacklistAttribute)) ||
      false
    )
  }

  const isDisabled = () => {
    return (
      loading ||
      (product?.isCustomOptionsRequired && !selectedCustomFields) ||
      (product?.second_choice
        ? Boolean(Number(product.second_choice))
        : false) ||
      !product?.deliverytime?.find(
        (item) => item.country_id === country.toUpperCase(),
      )?.deliverytime_unit ||
      isBlacklisted()
    )
  }
  const addToCart = () => {
    if (category !== '') {
      dispatch(setActiveItemCategory(category))
    }
    dispatch(actionAddToCart({ product, qty }))
    if (!microCart) {
      dispatch(setMicroCart(true))
      setTimeout(() => {
        dispatch(setMicroCart(false))
      }, 6000)
    }
  }
  if (
    (!detail && product?.isCustomOptionsRequired && !selectedCustomFields) ||
    (product?.second_choice && Boolean(Number(product.second_choice))) ||
    requestItem(product) ||
    (!detail &&
      !product?.deliverytime?.find(
        (item) => item.country_id === country.toUpperCase(),
      )?.deliverytime_unit)
  ) {
    return (
      <Link
        href={product?.url_path || ''}
        locale
        className={cn(
          'flex w-full items-center justify-center rounded border border-[#298400] bg-gradient-to-b from-[#46C154] to-[#097700] px-1 py-3 text-base font-semibold text-white outline-none hover:bg-gradient-to-b hover:from-[#33a741] hover:to-[#0C5F05] disabled:opacity-60 xl:text-sm 2xl:text-base',
          className,
        )}
      >
        <span>{i18n.t('To the product')}</span>
      </Link>
    )
  }

  return (
    <button
      className={cn(
        'flex w-full items-center justify-center rounded border border-[#298400] bg-gradient-to-b from-[#46C154] to-[#097700] px-1 py-3 text-base font-semibold text-white outline-none hover:bg-gradient-to-b hover:from-[#33a741] hover:to-[#0C5F05] disabled:opacity-60 xl:text-sm 2xl:text-base',
        className,
        { 'opacity-60': isDisabled() },
      )}
      onClick={addToCart}
      disabled={isDisabled()}
      {...props}
    >
      {isIcon && (
        <svg
          data-v-6b21d8f3=""
          data-v-15db4d7c=""
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="19"
          viewBox="0 0 17 17"
          aria-labelledby=""
          role="presentation"
          className="mr-2 text-white"
        >
          <g data-v-6b21d8f3="" fill="currentColor">
            <g data-v-15db4d7c="" data-v-6b21d8f3="">
              <path d="M15.111 19A1.9 1.9 0 1117 17.1a1.892 1.892 0 01-1.889 1.9zm-9.444 0a1.9 1.9 0 111.889-1.9A1.892 1.892 0 015.667 19zM17 14.25H5.667a1.9 1.9 0 01-1.89-1.9 1.846 1.846 0 01.236-.912l1.276-2.327-3.4-7.211H0V0h3.088l.888 1.9h6.035a5.21 5.21 0 005.4 7.543l-.016.029a1.88 1.88 0 01-1.652.978H6.706L5.856 12l-.028.115a.238.238 0 00.068.169.233.233 0 00.167.069H17v1.9z"></path>{' '}
              <g>
                <path d="M13.497 2.007H14.9v5.611h-1.403z"></path>{' '}
                <path d="M17.004 4.111v1.403h-5.611V4.111z"></path>
              </g>
            </g>
          </g>
        </svg>
      )}

      <span className="">{i18n.t('Add to cart')}</span>
    </button>
  )
}

AddToCartBtn.propTypes = {
  product: PropTypes.object,
  qty: PropTypes.number,
  detail: PropTypes.bool,
}

export default AddToCartBtn
