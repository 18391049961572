import PropTypes from 'prop-types'
import Link from '@components/ui/Link'
import Text from '@components/ui/Text'
import useI18n from '@utils/hooks/useI18n'
import Image from '@components/ui/Image'
const Exploded = ({ manual, exploded_view }) => {
  const i18n = useI18n()
  return (
    <div className="mb-2.5 leading-5">
      <div className="hidden list-none border-b border-[#9d9d9d] pb-1 pl-0 lg:block">
        <Text variant="inlineText" className="text-base font-light">
          {i18n.t('Download / Exploded drawing')}
        </Text>
      </div>

      <div className="flex flex-wrap justify-between">
        <ul className="flex py-[14px] pl-2.5">
          {exploded_view && exploded_view !== null && (
            <li className="inline-flex text-center">
              <Link
                href={exploded_view}
                external
                className="group text-[#345581] hover:text-[#3B73CC]"
              >
                <div className="relative mx-auto my-0 h-[151px] w-[117px]">
                  <Image
                    width={115}
                    height={150}
                    src="/preview-pdf-download.jpg"
                    alt="download pdf"
                  />
                  <div className="screen-full absolute inset-0 hidden items-center justify-center bg-[#00000080] group-hover:flex">
                    <Image
                      width={30}
                      height={36}
                      src="/dl-icon.png"
                      alt="download pdf"
                      className="absolute"
                    />
                  </div>
                </div>
                <Text
                  variant="inlineText"
                  className="line-clamp-3 w-36 text-sm font-light underline"
                >
                  {i18n.t('Click here to download the PDF')}
                </Text>
              </Link>
            </li>
          )}
          {manual && manual !== null && (
            <li className="inline-flex text-center">
              <Link external href={manual} className="group text-[#345581]">
                <div className="relative mx-auto my-0 h-[151px] w-[117px]">
                  <Image
                    width={115}
                    height={150}
                    src="/preview-pdf-download.jpg"
                    alt="download"
                  />
                  <div className="absolute inset-0 hidden bg-[#00000080] group-hover:block">
                    <Image
                      width={30}
                      height={36}
                      src="/dl-icon.png"
                      alt=""
                      className="absolute left-1/2 top-1/2 max-h-[40px] max-w-[30px] -translate-x-[40%] -translate-y-2/4"
                    />
                  </div>
                </div>
                <Text
                  variant="inlineText"
                  className="line-clamp-3 w-36 text-sm font-light underline"
                >
                  {i18n.t('Click here to download the PDF')}
                </Text>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
Exploded.propTypes = {
  manual: PropTypes.string,
  exploded_view: PropTypes.string,
}

export default Exploded
