import PropTypes from 'prop-types'
import Container from '@components/ui/Container'
import { useEffect, useState } from 'react'
import cn from '@utils/helpers/cn'
import { useSelector } from 'react-redux'
const DesktopStickyProduct = ({ children }) => {
  const [stickyProduct, setStickyProduct] = useState(false)
  const [navVisible, setNavVisible] = useState(true)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const { isMobile } = useSelector((state) => state.ui)
  function handleScroll() {
    const scrollTop = window.scrollY
    if (scrollTop > 550) {
      if (!stickyProduct) {
        setStickyProduct(true)
      }
    } else {
      if (stickyProduct) {
        setStickyProduct(false)
      }
    }
    if (scrollTop > lastScrollTop && scrollTop > 150) {
      if (navVisible) {
        setNavVisible(false)
      }
    } else {
      if (!navVisible) {
        setNavVisible(true)
      }
    }
    setLastScrollTop(scrollTop)
  }
  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [lastScrollTop, navVisible, stickyProduct])

  return (
    <>
      {stickyProduct && !isMobile && (
        <div
          className={cn(
            'fixed left-0 right-0 z-10 bg-white shadow-md transition-all delay-200 ease-in-out',
            {
              'top-[194px]': navVisible,
              'top-[156px]': !navVisible,
            },
          )}
        >
          <Container className="flex items-center justify-between">
            {children}
          </Container>
        </div>
      )}
    </>
  )
}

DesktopStickyProduct.propTypes = {
  children: PropTypes.element,
}
export default DesktopStickyProduct
