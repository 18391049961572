import Button from '@components/ui/Button'
import Icon from '@components/ui/Icon'
import { setLoginModal, setNotification } from '@features/ui/uiSlice'
import { actionWishlist } from '@features/user/userSlice'
import cn from '@utils/helpers/cn'
import useI18n from '@utils/hooks/useI18n'
import { userService } from '@utils/magento2'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
const AddToWishlist = ({ className, id, ...props }) => {
  const i18n = useI18n()
  const [isHover, setIsHover] = useState(false)
  const dispatch = useDispatch()
  const [isFav, setIsFav] = useState(false)
  const { current, wishlist } = useSelector((state) => state.user)
  const cartItems = useSelector((state) => state.cart?.products || [])
  const wishlistItemIds = wishlist?.items.find(
    (item) => item.product_id === id,
  )?.wishlist_item_id

  const [isCart, setIsCart] = useState(false)

  useEffect(() => {
    const productIsCart = cartItems.find((item) => item.id === id)?.id
      ? true
      : false
    if (productIsCart !== isCart) {
      setIsCart(productIsCart)
    }
  }, [id, cartItems.length])

  const addToWishlist = async () => {
    const productId = id
    let res = {}
    if (current) {
      const wishlistId = wishlist.wishlist_id
      if (isFav) {
        res = await userService.removeWishListItem(
          [wishlistItemIds],
          wishlistId,
        )
        if (res.code === 200) {
          setIsFav(false)
          dispatch(actionWishlist())
          dispatch(
            setNotification({
              type: 'success',
              message: i18n.t(
                'The product was successfully removed from your wishlist.',
              ),
            }),
          )
        }
      } else {
        res = await userService.addToWishlist({ productId, wishlistId })
        if (res.code === 200) {
          dispatch(actionWishlist())
          dispatch(
            setNotification({
              type: 'success',
              message: i18n.t([
                'The product was successfully added to your wishlist.',
              ]),
            }),
          )
          setIsFav(true)
        }
      }
    } else {
      dispatch(setLoginModal(true))
    }
  }
  useEffect(() => {
    if (current && wishlist?.items?.length) {
      if (wishlist.items.find((item) => item.product_id === id)) {
        setIsFav(true)
      }
    }
  }, [id])
  return (
    <Button
      variant="normal"
      className={cn(
        'aspect-square bg-gradient-to-b from-[#f7f7f7] to-[#fff] hover:bg-gradient-to-b hover:from-[#f7f7f7] hover:to-[#fff] disabled:bg-[#cccccc]',
        className,
        {
          'pointer-events-none': isCart,
        },
      )}
      onClick={addToWishlist}
      {...props}
    >
      {isCart ? (
        <Icon
          icon={'icon-checkmark'}
          className="block aspect-square size-7 rounded-full border-2 border-[#46C154] bg-[#097700] pt-1 text-xs text-white md:size-9 md:text-base"
        />
      ) : (
        <Icon
          icon={isHover && isFav ? 'icon-heart-broken' : 'icon-heart1'}
          className={cn(
            'transform text-2xl transition-transform duration-200 hover:scale-125 hover:text-[#c7c7c7]',
            isFav ? 'text-red-600' : 'text-[#A5A5A5]',
          )}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        />
      )}
    </Button>
  )
}

AddToWishlist.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default AddToWishlist
