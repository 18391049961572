import { store } from '@features/store'

export function requestItem(product) {
  // Input validation
  if (!product?.request_item) {
    return false
  }

  // Get attribute data from store
  const attributeByCode = store.getState()?.attribute?.byCode
  if (!attributeByCode?.request_item?.options) {
    return false
  }

  try {
    // Find matching option
    const option = attributeByCode.request_item.options.find(
      (item) => item && parseInt(item.value) === parseInt(product.request_item),
    )

    // Validate option and label
    if (!option?.label || option.label === 'empty') {
      return false
    }

    return option.label
  } catch (error) {
    console.error('Error processing request item:', error)
    return false
  }
}

export default requestItem
